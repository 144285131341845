import $ from 'jquery';


export default class SlickSlider {
    init() {
        $('[data-slick]').not('.slick-initialized').each(function() {
            let self = $( this );
            if(self.data('count') === 1){
                return;
            }

            self.slick({
                prevArrow:"<button type=\"button\" class=\"slick-prev slick-arrow\"><svg version=\"1.1\" id=\"slide-left\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"22px\" height=\"62px\" viewBox=\"0 0 22 62\" enable-background=\"new 0 0 22 62\" xml:space=\"preserve\"><path d=\"M21,61.5c-0.161,0-0.319-0.078-0.416-0.223l-20-30c-0.112-0.168-0.112-0.387,0-0.555l20-30c0.154-0.23,0.465-0.291,0.693-0.139c0.229,0.153,0.292,0.463,0.139,0.693L1.601,31l19.815,29.723c0.153,0.229,0.091,0.54-0.139,0.693C21.192,61.473,21.096,61.5,21,61.5z\"/></svg></button>",
                nextArrow:"<button type=\"button\" class=\"slick-next slick-arrow\"><svg version=\"1.1\" id=\"slide-right\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" x=\"0px\" y=\"0px\" width=\"22px\" height=\"62px\" viewBox=\"0 0 22 62\" enable-background=\"new 0 0 22 62\" xml:space=\"preserve\"><path d=\"M1,61.5c-0.095,0-0.191-0.027-0.277-0.084c-0.23-0.153-0.292-0.464-0.139-0.693L20.399,31L0.584,1.277c-0.153-0.23-0.091-0.54,0.139-0.693c0.229-0.154,0.54-0.091,0.693,0.139l20,30c0.112,0.168,0.112,0.387,0,0.555l-20,30C1.32,61.422,1.161,61.5,1,61.5z\"/></svg></button>"
            });
        });
    }

}
